import React from 'react';
import { graphql } from 'gatsby';
//import PortableText from 'react-portable-text';
import loadable from '@loadable/component';
import { navigate } from 'gatsby';

import {
  Container,
  Grid,
  Typography,
  Button,
  useTheme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { Hero } from '../../components/General/Hero';
import { WaveUpSVG } from '../../components/WaveSVGs/WaveUpSVG';
import { WaveDownSVG } from '../../components/WaveSVGs/WaveDownSVG';
import { SEO } from '../../components/SEO';
import { Overview } from '../../components/FinancialServices/Product/Overview';
import { Feature } from '../../components/FinancialServices/Product/Feature';
import { Resources } from '../../components/General/Resources';
import { ProductFeatureBody } from '../../components/ProductFeature/ProductFeatureBody';
import { HeroVideo } from '../../components/FinancialServices/Product/Hero/HeroVideo';
// import { Form } from '../../components/ContactForm/Form';

const Form = loadable(() => import('../../components/ContactForm/Form'));

const useStyles = makeStyles((theme) => ({
  formCont: {
    padding: '24rem 0',
    [theme.breakpoints.down('md')]: {
      padding: '16rem 0',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '8rem 0',
    },
  },
  faqTitle: {
    color: theme.workwaveBlue,
  },
}));

const Fintech = ({ data, location }) => {
  const product = data.fs.edges[0].node.product;
  const classes = useStyles();

  const lg = useMediaQuery('(max-width: 1280px)');
  const theme = useTheme();

  const {
    productPageHero,
    //_rawContent,
    metaDescription,
    metaTitle,
    //_rawProductOverview,
    //productFeatureSection,
    contactForm,
    formImages,
    formBgImage,
    marketoId,
    pardotUrl,
    resourceTitle,
    resources,
    _rawResourceBody,
    faqCTALink,
    faqCTAText,
    faqTitle,
    thumbnailImage,
    wistiaLink,
  } = product;
  return (
    <>
      <SEO title={metaTitle} description={metaDescription} />
      <Hero hero={productPageHero} finServ />
      <Container>
        <HeroVideo thumbnailImage={thumbnailImage} wistiaLink={wistiaLink} />
      </Container>
      <ProductFeatureBody productFeature={product} />
      {/* <Overview overview={_rawProductOverview} />
				{productFeatureSection.map((feature, index) => (
					<Feature
						feature={feature}
						index={index}
						accentColor={theme.workwaveBlue}
					/>
				))} */}
      <Container>
        <Grid
          style={{ padding: lg ? '2rem 0 ' : '4rem 0 0 0' }}
          container
          spacing={4}
          justifyContent='center'
          alignItems='center'
          direction='row'
        >
          <Grid item xs={12} container justifyContent='center'>
            <Typography variant='h2' className={classes.faqTitle}>
              {faqTitle}
            </Typography>
          </Grid>
          <Grid item container justifyContent='center'>
            <Button
              variant='contained'
              size='large'
              className={classes.button}
              style={{
                background: theme.workwaveBlue,
                color: 'white',
              }}
              onClick={(e) => {
                e.preventDefault();
                navigate(faqCTALink);
              }}
            >
              {faqCTAText}
            </Button>
          </Grid>
        </Grid>
      </Container>
      <div>
        <WaveDownSVG height='213' width='100%' fill='white' />
      </div>

      <div
        className={classes.formCont}
        style={{
          backgroundImage: `url(${formBgImage?.asset?.gatsbyImageData?.images.fallback.src})`,
          backgroundSize: 'cover',
        }}
      >
        <Form
          formId={marketoId}
          pardotUrl={pardotUrl}
          contactForm={contactForm}
          privacy
          modal={false}
          formImages={formImages}
          location={location}
        />
      </div>
      <WaveUpSVG height='213' width='100%' fill='white' />
      <Container>
        <Resources
          header={resourceTitle}
          resources={resources}
          subheader={_rawResourceBody}
        />
      </Container>
      <div>
        <WaveUpSVG height='213' width='100%' fill='#f4f8ff' />
      </div>
    </>
  );
};

export const query = graphql`
  query FSProductPageQuery {
    fs: allSanityFinancialServices {
      edges {
        node {
          product: fsProductPage {
            metaTitle
            metaDescription
            productPageHero {
              ctaText
              backgroundImage {
                asset {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
              statsImage {
                asset {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
              _rawContent
            }
            # productOverview {
            #   _rawChildren
            # }
            thumbnailImage {
              asset {
                gatsbyImageData
              }
            }
            wistiaLink
            featureHeader
            featureSection: productFeatureSection {
              _key
              title
              cardTitle
              cardFaIcon
              cardIconColor {
                hexValue
              }
              bodySubHeader
              bodyHeader
              image {
                featureImage {
                  asset {
                    gatsbyImageData(placeholder: BLURRED)
                  }
                }
              }
              videoVariant
              androidLink
              iosLink
              qbLink
              # svgVariant
              thumbnailVideoUrl
              ctaText
              ctaLink
              internalLink
              _rawBodyCopy
            }
            testimonialTitle
            testimonial {
              title
              header
              testimonialLogo {
                asset {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
              company
              testimonialText
              nameAndTitle
              videoVariant
              image {
                asset {
                  gatsbyImageData(
                    fit: FILLMAX
                    height: 450
                    width: 775
                    placeholder: BLURRED
                  )
                }
              }
            }
            testimonialBackground {
              asset {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            customerLogosHeader
            customerLogos {
              logo {
                asset {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
            contactForm {
              header
              privacyPolicy
            }
            formImages {
              image {
                asset {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
              link
            }
            formBgImage {
              asset {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            marketoId
            pardotUrl
            resourceTitle
            resources {
              title
              image {
                asset {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
              blurb
              ctaText
              ctaLink
            }
            # _rawProductOverview
            _rawResourceBody
            faqCTALink
            faqCTAText
            faqTitle
          }
        }
      }
    }
  }
`;

export default Fintech;
